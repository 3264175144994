import storage from "@/storage";
import { lauderLarken } from "@/plugins/axios";
import { dateFormat } from "@/functions";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    time: null,
    isModalOpen: false,
    fetchedData: [],
    loading: true,
    config: {
      dbAllKey: "",
      dbCertainKey: "",
      objectDbKey: "",
      axiosLink: "",
      dbPutKey: ""
    },
    loaded: false
  }),
  computed: {
    ...mapGetters({
      db: "laudergetdb",
      loginState: "laudergetLoginState",
      token: "customToken",
      selected: "lauderGetSelectedAss",
      mgmt: "isMgmt"
    })
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.fetchIt();
      }
    }
  },
  methods: {
    dateFormat,
    async getData() {
      let searchForOld = [];
      if (this.selected.AllAss) {
        searchForOld = await storage[this.config.dbAllKey](this.db);
        this.time = searchForOld[searchForOld.length - 1]?.addedAt;
        let Fdefault = [];
        searchForOld.map(r => {
          return (Fdefault = Fdefault.concat(...r[this.config.objectDbKey]));
        });
        searchForOld = Fdefault.map((r, i) => ({ ...r, id: i + 1 }));
      } else {
        searchForOld = await storage[this.config.dbCertainKey](
          this.filterAssName(this.token),
          this.db,
          false
        );
        this.time = searchForOld?.addedAt;
        searchForOld =
          searchForOld &&
          searchForOld?.results.length > 0 &&
          searchForOld?.results.map((r, i) => ({ ...r, id: i + 1 }));
      }
      return searchForOld || [];
    },
    async fetchIt(forceUpdate = false) {
      this.loading = true;
      let searchForOld = [];
      if (!forceUpdate) {
        searchForOld = await this.getData();
      }
     
      if (searchForOld && searchForOld.length > 0) {
        this.fetchedData = searchForOld;
      } else {
        await this.CallIt();
      }
      if (!this.time) {
        this.time = Date.now();
      }
      this.loading = false;
    },
    async CallIt() {
      try {
        if (!this.loginState) return;
        let { data } = await lauderLarken[this.config.axiosLink]();
        const real_data = data.data;
        const group = real_data.reduce((acc, item) => {
          if (!acc[item.Client_Associations]) {
            acc[item.Client_Associations] = [];
          }
          acc[item.Client_Associations].push(item);

          return acc;
        }, {});
        try {
          for (const [key, value] of Object.entries(group)) {
            await storage[this.config.dbPutKey](
              value,
              this.filterAssName(key),
              this.db
            );
          }
        } catch (e) {
          console.log(e);
        }
        this.loaded = true;
        this.fetchedData = await this.getData();
        // this.fetchedData
        console.log("fetched", this.fetchedData);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
