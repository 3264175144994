<template>
  <div>
    <grid_v_card
      :data="fetchedData || []"
      :columns="columns"
      no_padding
      @update="fetchIt(true)"
      :loading="loading"
      :time="time"
      label="Open Matters Payments"
      :export_name="dateFormat() + ' All Payments'"
    ></grid_v_card>
    <payment v-model="isModalOpen" :current="current" />
  </div>
</template>

<script>
import DataGrid from "@/mixins/DataGrid";
export default {
  mixins: [DataGrid],
  name: "payments_data_grid",
  data() {
    return {
      current: {},
      config: {
        dbAllKey: "getAllPayments",
        dbCertainKey: "getCertainPayments",
        objectDbKey: "payments",
        axiosLink: "getPaymentData",
        dbPutKey: "setCertainPayments"
      },
      columns: [
        {
          headerName: "#",
          field: "id",
          type: "text",
          exportable: false,
          colId: "Pay_Id",
          cellRenderer: params => {
            const link = document.createElement("a");
            link.innerText = "Details";
            link.className =
              "v-btn v-btn--rounded v-btn--tile theme--dark v-size--small secondary ml-3";
            if (params.value) {
              link.addEventListener("click", e => {
                e.preventDefault();
                this.isModalOpen = true;
                this.current = params.data;
              });
            }
            return link;
          }
        },
        // {
        //   headerName: "Client",
        //   field: "client_ID",
        //   type: "text",
        //   colId: "client_ID"
        // },
        {
          headerName: "Amount",
          field: "Payment Amount",
          type: "currency",
          colId: "Payment Amount"
        },
        {
          headerName: "Date",
          field: "Payment Date",
          type: "date",
          colId: "Payment Date"
        },
        {
          headerName: "Status",
          field: "Status",
          type: "status",
          colId: "Status"
        },
        //       {
        //         headerName: "Association",
        //         field: "Client_Associations",
        //       type: "text",
        //       flex: 1,
        //       colId: "Association Name"
        // },
        // {
        //   headerName: "Company",
        //   field: "Management Company",
        //   type: "text",
        //   colId: "Managment Company"
        // },
       
      ]
    };
  },
  components: {
    payment: () => import("@/views/payment"),
    grid_v_card: () => import("@/components/grid_v_card")
  }
};
</script>

<style scoped></style>
